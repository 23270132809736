<template>
  <cw-page
    icon="warning"
    class="application__rejected"
  >
    <v-row>
      <v-col>
        <!-- Existing customers -->
        <template v-if="getIsCurrentCustomer">
          <p>
            <translate>
              At this time, we are unable to grant you a new loan.
            </translate>
          </p>
          <p>
            <translate>
              By logging into MySaldo, you can manage all your Tact Finance loans quickly and
              easily.
            </translate>
          </p>
        </template>

        <!-- New customers -->
        <template v-else>
          <p>
            <translate>
              Unfortunately we are unable to grant you a loan at this point based
              on your financial situation. We perform credit worthiness checks based
              on information provided by Bisnode, which includes possible payment remarks.
              Please try again when your financial situation has improved.
            </translate>
          </p>

          <div
            v-if="!isCarLoan"
            id="cw-broker-container"
          >
            <p>
              <translate>
                However, you still have the opportunity to apply for a loan by submitting your
                contact information to the Omalaina -loan service.
              </translate>
            </p>
          </div>
        </template>
      </v-col>
    </v-row>

    <template v-if="!isCarLoan" #actions>
      <v-spacer/>

      <template v-if="getIsCurrentCustomer">
        <v-btn
          id="application__rejected__mysaldo_link"
          color="primary"
          href="https://mysaldo.tact.fi"
          @click="$eventLogger.clickEvent($event)"
        >
          <translate>
            Go to MySaldo
          </translate>
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          v-if="isSaldo"
          id="application__rejected__omalaina_link"
          color="primary"
          href="https://www.saldo.com/fi-fi/whitelabel/sap/index.html"
          @click="$eventLogger.clickEvent($event)"
        >
          <translate>
            Apply for a loan from Omalaina-service
          </translate>
        </v-btn>

        <v-btn
          v-else
          id="application__rejected__omalaina_link"
          color="primary"
          href="https://www.vippi.fi/fi-fi/whitelabel/sap/index.html"
          @click="$eventLogger.clickEvent($event)"
        >
          <translate>
            Apply for a loan from Omalaina-service
          </translate>
        </v-btn>
      </template>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwRejection',

  computed: {
    ...mapGetters({
      getIsCurrentCustomer: 'application/getIsCurrentCustomer',
      isCarLoan: 'application/isCarLoan',
      isSaldo: 'application/isSaldo',
    }),
  },

  async created() {
    await this.isCurrentCustomer();
  },

  methods: {
    ...mapActions({
      isCurrentCustomer: 'application/isCurrentCustomer',
    }),
  },
};
</script>
